import React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import './AboutUs.css';


const AboutUs = () => {
  return (
    <div className="">
      <div className=''>
        <div className='container'>
          <div className="grid md:grid-cols-2 grid-cols-1">
            <div className="relative z-10">
              <div className="bg-[#2da7c9]  shadow-[0_0px_20px_2px_white] md:-top-[150px] top-0 left-0 md:absolute relative">
                <div className=" pt-[60px] pb-[65px] px-[45px] ">
                  <h3 className='text-[26px] leading-[29px] tracking-[1.2] uppercase font-normal text-[#deba27]'>WHO WE ARE</h3>
                  <h2 className='text-[60px] leading-[65px] font-normal text-[#fff] mb-[15px]'>THE LEADING APPAREL MANUFECTURE AND EXPORTER</h2>
                  <p className='text-[16px] text-white leading-[26px] font-normal'>
                    It's great to hear that Na Alam Sourching Bd Ltd is a leading multinational apparel buying hub,
                    sourcing company, and manufacturer committed to producing high-quality garments for internationally.
                    Building long-term trade relationships with customers is essential to establish a strong presence in the
                    market and earn trust from potential clients. The company was incorporated in 2018 by The
                    collaboration of two partners.
                  </p>
                  <Link to="/about" className='text-[18px] font-medium text-[#deba27] hover:text-black border-b-[1px] border-[#deba27] hover:border-black uppercase'>View More</Link>

                </div>
                <div className=" overflow-hidden relative">
                  <div className="about-thumbnail object-cover max-h-full">
                    <img className='w-full h-full transition-all duration-500 object-cover' src="https://i.ibb.co/GpBcJwH/Whats-App-Image-2023-02-20-at-10-49-31-PM.jpg" alt="" srcset="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="bg-[#2da7c9] z-[9999]">
                <div className=" pt-[60px] pb-[65px] px-[45px] ">
                  <h3 className='text-[26px] leading-[29px] tracking-[1.2] uppercase font-normal text-[#deba27]'>N Alam Sourcing Bd Ltd</h3>
                  <h2 className='text-[60px] leading-[65px] font-normal text-[#FFF] mb-[15px]'>We Are In Apparel Industries</h2>
                  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                    <div data-aos="zoom-in-up" data-aos-duration="2000" className="counter-box border pt-[35px] pb-[40px] px-[30px] bg-white border-[#eaeaea] mx-[5px] relative">
                      <img className='w-full' src='https://i.ibb.co/MfZ3C7C/trusted-clients-new2-01.png' alt='trusted-clients' />
                      <h2 className='text-[45px] leading-[50px] font-semibold'>
                        <CountUp start={0} end={6} enableScrollSpy /> +
                      </h2>
                      <h3 className='text-[16px] font-medium leading-[26px] tracking-[1.2px]'>TRUSTED CLIENTS</h3>

                      <div className="counter-box-left-border duration-500 w-[3px] h-[70%] bg-[#DEBA27FF] absolute top-[15%] left-0"></div>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-duration="2000" className="counter-box border pt-[35px] pb-[40px] px-[30px] bg-white border-[#eaeaea] mx-[5px] relative">
                      <img className='w-full' src='https://i.ibb.co/grBsvJk/shipment-2-new-01.png' alt='shipment' />
                      <h3 className='text-[16px] font-normal leading-[26px] tracking-[1.2px]'>More than</h3>
                      <h2 className='text-[45px] leading-[50px] font-semibold'>
                        <CountUp start={0} end={3000} enableScrollSpy />
                      </h2>
                      <h3 className='text-[16px] font-medium leading-[26px] tracking-[1.2px]'>SHIPMENTS</h3>

                      <div className="counter-box-left-border duration-500 w-[3px] h-[70%] bg-[#DEBA27FF] absolute top-[15%] left-0"></div>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-duration="2000" className="counter-box border pt-[35px] pb-[40px] px-[30px] bg-white border-[#eaeaea] mx-[5px] relative">
                      <img className='w-full' src='https://i.ibb.co/rHTVNFZ/years-of-experience-01.png' alt='years-of-experience' />
                      <h2 className='text-[45px] leading-[50px] font-semibold'>
                        <CountUp start={0} end={4} enableScrollSpy /> +
                      </h2>
                      <h3 className='text-[16px] font-medium leading-[26px] tracking-[1.2px]'>YEARS OF EXPERIENCE</h3>

                      <div className="counter-box-left-border duration-500 w-[3px] h-[70%] bg-[#DEBA27FF] absolute top-[15%] left-0"></div>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-duration="2000" className="counter-box border pt-[35px] pb-[40px] px-[30px] bg-white border-[#eaeaea] mx-[5px] relative">
                      <img className='w-full' src='https://i.ibb.co/6XGbfLm/visited-conference-new-01.png' alt='visited-conference' />
                      <h2 className='text-[45px] leading-[50px] font-semibold'>
                        <CountUp start={0} end={5} enableScrollSpy /> +
                      </h2>
                      <h3 className='text-[16px] font-medium leading-[26px] tracking-[1.2px]'>VISITED CONFERENCE</h3>

                      <div className="counter-box-left-border duration-500 w-[3px] h-[70%] bg-[#DEBA27FF] absolute top-[15%] left-0"></div>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-duration="2000" className="counter-box border pt-[35px] pb-[40px] px-[30px] bg-white border-[#eaeaea] mx-[5px] relative">
                      <img className='w-full' src='https://i.ibb.co/jvRPgSB/compliance-factories-01.png' alt='compliance-factories' />
                      <h2 className='text-[45px] leading-[50px] font-semibold'>
                        <CountUp start={0} end={15} enableScrollSpy /> +
                      </h2>
                      <h3 className='text-[16px] font-medium leading-[26px] tracking-[1.2px]'>COMPLIANCE FACTORIES</h3>

                      <div className="counter-box-left-border duration-500 w-[3px] h-[70%] bg-[#DEBA27FF] absolute top-[15%] left-0"></div>
                    </div>
                    <div data-aos="zoom-in-up" data-aos-duration="2000" className="counter-box border pt-[35px] pb-[40px] px-[30px] bg-white border-[#eaeaea] mx-[5px] relative">
                      <img className='w-full' src='https://i.ibb.co/8rrQC5v/monthly-production-01.png' alt='monthly-production' />
                      <h2 className='text-[45px] leading-[50px] font-semibold'>
                        <CountUp start={0} end={1} enableScrollSpy /> M
                      </h2>
                      <h3 className='text-[16px] font-medium leading-[26px] tracking-[1.2px]'>PCS/MONTH PRODUCTION</h3>

                      <div className="counter-box-left-border duration-500 w-[3px] h-[70%] bg-[#DEBA27FF] absolute top-[15%] left-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;