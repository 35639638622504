import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link, NavLink, Outlet } from 'react-router-dom';
import ServiceSideBar from './ServiceSideBar/ServiceSideBar';

const ServiceLayout = () => {
  return (
    <div>
      <div className="bg-[url(https://i.ibb.co/GVjWPRN/OIP-1.jpg)] bg-no-repeat bg-cover block mb-24">
        <div className="bg-[#0000009a] bg-opacity-5">
          <div className="text-center py-[75px]">


            <NavLink to='/service/research' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Research & Development</h2>
             
            </NavLink>

            <NavLink to='/service/sample' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Sample Development</h2>
             
            </NavLink>


            <NavLink to='/service/merchandising' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Merchandising</h2>
             
            </NavLink>


            <NavLink to='/service/fabric' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Fabric Sourcing</h2>
             
            </NavLink>


            <NavLink to='/service/production' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Production</h2>
             
            </NavLink>


            <NavLink to='/service/qaqc' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>QA and QC</h2>
             
            </NavLink>


            <NavLink to="/service/delivery" className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Delivery and Shipment</h2>
             
            </NavLink>


            <NavLink to='/service/kitting' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Knitting</h2>
             
            </NavLink>


            <NavLink to='/service/embroidery' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Embroidery</h2>
             
            </NavLink>


            <NavLink to='/service/garment' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Garment Dyeing & Wash</h2>
             
            </NavLink>


            <NavLink to='/service/printing' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Printing</h2>
             
            </NavLink>


            <NavLink to='/service/trimming' className={({ isActive }) =>
              isActive
                ? "cursor-text block"
                : "cursor-text hidden"}>
              <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>Trimming & Accessories</h2>
             
            </NavLink>

            <div>
              <ul className="flex gap-1 justify-center items-center">
                <li>
                  <Link to='/' className=''>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Home</p>
                  </Link>
                </li>

                <FaCircle className='text-[15px] leading-[20px] capitalize text-[#deba27]' />
                <li>
                  <Link to='/services' className=''>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Services</p>
                  </Link>
                </li>

                <FaCircle className='text-[15px] leading-[20px] capitalize text-[#deba27]' />
                <li>
                  <NavLink to='/service/research' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Research & Development</p>

                  </NavLink>

                  <NavLink to='/service/sample' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Sample Development</p>

                  </NavLink>


                  <NavLink to='/service/merchandising' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Merchandising</p>

                  </NavLink>


                  <NavLink to='/service/fabric' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Fabric Sourcing</p>

                  </NavLink>


                  <NavLink to='/service/production' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Production</p>

                  </NavLink>


                  <NavLink to='/service/qaqc' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>QA and QC</p>

                  </NavLink>


                  <NavLink to="/service/delivery" className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Delivery and Shipment</p>

                  </NavLink>


                  <NavLink to='/service/kitting' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Knitting</p>

                  </NavLink>


                  <NavLink to='/service/embroidery' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Embroidery</p>

                  </NavLink>


                  <NavLink to='/service/garment' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Garment Dyeing & Wash</p>

                  </NavLink>


                  <NavLink to='/service/printing' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Printing</p>

                  </NavLink>


                  <NavLink to='/service/trimming' className={({ isActive }) =>
                    isActive
                      ? "cursor-text block"
                      : "cursor-text hidden"}>
                    <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>Trimming & Accessories</p>

                  </NavLink>
                </li>
                {/* <li>
                  <p className='text-[16px] font-medium leading-[20px]  text-[#FFFFFF]'>About Us</p>
                </li> */}

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='container mb-24'>
        <div className='flex gap-8 lg:flex-row flex-col-reverse'>
          <div className='lg:w-[30%]'>
            <ServiceSideBar />
          </div>
          <div className='lg:w-[70%]'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceLayout;