import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import Slider from 'react-slick';

const Testimonials = () => {


  const [sliderSettings, setSliderSettings] = useState({
    // dots: true,
    infinite: true,
    // speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    arrows: false,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1351) {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 1,
        });
      }
      else if (window.innerWidth >= 750 && window.innerWidth <= 1350) {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 1,
        });
      } else if (window.innerWidth <= 750) {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 1,
        });
      }
      else {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 1,
        });
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [sliderSettings]);

  return (
    <div className='overflow-hidden'>

      <div className="bg-[#2da7c9]">
        <div className="">
          <div className="container">
            <div className="grid md:grid-cols-2 grid-cols-1">
              <div className="pl-[15px] pt-[115px] pb-[100px] pr-[100px] bg-[url(https://www.warptexbd.com/home-4/images/bg-image/col-bgimage-04.png)] bg-no-repeat" style={{ backgroundPosition: 'right -1% top -5%' }}>
                <h3 className='text-[26px] leading-[29px] tracking-[1.2] text-[#deba27]'>Who We Are!</h3>
                {/* <h2 className='text-[60px] leading-[65px] font-normal mb-[15px] text-white'>What Our Clients Say!</h2> */}

                {/* <div className="">
                  <Slider {...sliderSettings}>
                    <div className="">
                      <div className="">
                        <div className="w-[80%] mx-auto">
                          <div className="flex items-center">
                            <div className="avatar ml-0 pr-5 border-r-[6px] border-r-[#dabe27]">
                              <div className="w-24 rounded-full">
                                <img src="https://www.warptexbd.com/image/testimonial/20230119053518.jpeg" alt="" />
                              </div>
                            </div>

                            <div className="ml-5">
                              <h4 className='text-2xl font-medium text-white mt-5'>Ezekiel Pullings</h4>
                              <p className='text-[16px] font-normal text-[#ffffff99]'>Production Director</p>
                            </div>
                          </div>
                          <article className='transition-all duration-500 text-center'>
                            <div className="vounteerName text-start mt-2 text-">
                              <p className='text-[#ffffff99] font-semibold text-[16px] italic my-2'>Best place for your denim products. Their price is very competitive of others and the quality is topnotch. Wraptex has been the supplier for my denims for almost 8 years now. Wish them nothing but success. They definitely have my trust.</p>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="w-[80%] mx-auto">
                          <div className="flex items-center">
                            <div className="avatar ml-0 pr-5 border-r-[6px] border-r-[#dabe27]">
                              <div className="w-24 rounded-full">
                                <img src="https://www.warptexbd.com/image/testimonial/20230119053623.jpeg" alt="" />
                              </div>
                            </div>

                            <div className="ml-5">
                              <h4 className='text-2xl font-medium text-white mt-5'>Tyra Mason</h4>
                              <p className='text-[16px] font-normal text-[#ffffff99]'>Senior Buyer</p>
                            </div>
                          </div>
                          <article className='transition-all duration-500 text-center'>
                            <div className="vounteerName text-start">
                              <p className='text-[#ffffff99] font-semibold text-[16px] my-2 italic'>Anyone can give you a quotation and make things without assuring the quality. This organization have proved to me that reasonable pricing can be achieved without compromising on quality. I am very keen on my product quality and these people have met my expectations.</p>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="w-[80%] mx-auto">
                          <div className="flex items-center">
                            <div className="avatar ml-0 pr-5 border-r-[6px] border-r-[#dabe27]">
                              <div className="w-24 rounded-full">
                                <img src="https://www.warptexbd.com/image/testimonial/20230119053338.jpeg" alt="" />
                              </div>
                            </div>

                            <div className="ml-5">
                              <h4 className='text-2xl font-medium text-white mt-5'>Stacy K. Miller</h4>
                              <p className='text-[16px] font-normal text-[#ffffff99]'>Brand Manager</p>
                            </div>
                          </div>
                          <article className='transition-all duration-500 text-center'>
                            <div className="vounteerName text-start">
                              <p className='text-[#ffffff99] font-semibold text-[16px] my-2 italic'>They are very responsive. They have always kept me upto date with “actual” production status and never mislead me in anyway, which is hard to come by these days.</p>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <div className="w-[80%] mx-auto">
                          <div className="flex items-center">
                            <div className="avatar ml-0 pr-5 border-r-[6px] border-r-[#dabe27]">
                                 <div className="w-24 rounded-full">
                                 <img src="https://www.warptexbd.com/image/testimonial/20230119053150.jpeg" alt="" />
                                 </div>
                            </div>

                            <div className="ml-5">
                              <h4 className='text-2xl font-medium text-white mt-5'>David Jones</h4>
                              <p className='text-[16px] font-normal text-[#ffffff99]'>Sourcing Director</p>
                            </div>
                          </div>
                          <article className='transition-all duration-500 text-center'>
                            <div className="vounteerName text-start">
                              <p className='text-[#ffffff99] font-semibold text-[16px] my-2 italic'>I have been working with Wraptex for the last few years. Never did I have a chance to complain about their services. The order are always on point. I have still not received any delayed delivery from them, hope they can keep it up.</p>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div> */}
                <p className='text-[#ffffff]'>Our robust team of professional experts consists of experienced and dynamic individuals.
                   Our unassailable lead ensures the service to be immaculate, all the way from product development to the final shipment.
                    It has always been our solemn vow to abide by all the local and international compliances that apply to our line of work.
                     To ensure the best quality products, our sourcing team is comprised of extensively experienced sourcing professionals with extensive market knowledge. 
                     We employ veteran quality control experts with years of experience, who make sure the client gets exactly what they asked for.
                      For the shipment procedures, we work with the best C&F agencies in the country to guarantee smooth sailing to the customer’s desired port.
                       From the very beginning, we have established ourselves as a one stop product sourcing, design, production, quality assurance and sustainable supply chain solution to all of our international clients.</p>  
              </div>
              <div className="">
                <div className="bg-[url(https://www.warptexbd.com/image/testimonial/testimonials.jpg)] bg-left bg-no-repeat bg-[100%] h-[550px] -mr-[200px]">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#deba27] py-[40px]">
        <div className="container">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className="mx-[15px]">
              <h3 className='uppercase text-white text-[30px] leading-[29px] tracking-[1.2px] mb-4 font-normal'>AT A GLANCE</h3>
              <h2 className='text-[50px] text-white font-normal leading-[46px] '><span className='text-[#012740]'>N Alam Sourcing Bd Ltd</span> Is Operating In Apparel Industries</h2>
            </div>
            <div className="mx-[15px]">
              <div data-aos="fade-up" data-aos-duration="2000" className="mb-[10px]">
                <div className="flex items-center justify-between">
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>R&D And Product Development</p>
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>100%</p>
                </div>
                <div className=" border-[2px] border-white  mt-2"><div className="h-2 my-[3px] mx-[5px] bg-white"></div></div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" className="mb-[10px]">
                <div className="flex items-center justify-between">
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>On Time Delivery</p>
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>100%</p>
                </div>
                <div className=" border-[2px] border-white  mt-2"><div className="h-2 my-[3px] mx-[5px] bg-white"></div></div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" className="mb-[10px]">
                <div className="flex items-center justify-between">
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>Fast Response & Sample Submission</p>
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>100%</p>
                </div>
                <div className=" border-[2px] border-white  mt-2"><div className="h-2 my-[3px] mx-[5px] bg-white"></div></div>
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" className="mb-[10px]">
                <div className="flex items-center justify-between">
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>Quality Standard 2.5 AQL</p>
                  <p className='text-white text-[20px] leading-[25px] font-normal capitalize'>100%</p>
                </div>
                <div className=" border-[2px] border-white  mt-2"><div className="h-2 my-[3px] mx-[5px] bg-white"></div></div>
              </div>
            </div>
            <div className="mx-[15px]">
              <div className="bg-[#2da7c9] pr-[15px] pl-[30px] py-[30px] max-w-[300px]">
                <div className="flex items-center">
                  <i class="fa-solid fa-users text-white text-[65px]"></i>
                  <div className="ml-[20px]">
                    <h2 className='text-[45px] leading-[50px] font-semibold text-white'>
                      <CountUp start={0} end={6} enableScrollSpy /> +
                    </h2>
                    <p className='text-[17px] leading-[26px] text-white font-normal'>Happy Customer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;