import React from 'react';

const ContactMapAddress = () => {
  return (
    <div className='container py-[100px]'>
      <div className=" block items-center">
        {/* <div className="md:w-5/12 w-full mx-4">
          <img className='w-full' src="https://i.ibb.co/YtV7Hjb/map1.png" alt="" srcset="" /> 
        </div>*/}
        <div className=" w-full mx-4">
          <div className="pb-[11px]">
            <h3 className='text-[26px] leading-[29px] text-[#deba27] uppercase font-normal'>CONTACT US</h3>
            <h3 className='text-[60px] leading-[65px] text-white mb-4 uppercase font-normal'>Get In <b>Touch!</b></h3>
          </div>
          <h2 className='text-[24px] text-white leading-[26px] pt-[10px] border-b-[1px] pb-[15px]'>Head Office</h2>
          <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-6 items-baseline	">

            <div className="flex">
              <div className="h-[55px] w-[57px] leading-[57px] rounded-[100%] mr-2 flex items-center justify-center bg-[#f7f7f7]" >
                <i className='fa-solid fa-location-dot text-[#deba27] text-[37px]'></i>
              </div>
              <div>
                <h3 class="text-[22px] text-white leading-[30px] capitalize font-medium">Address</h3>
                <p className='text-white'>  House: 768(Flat-1B), Road: 10 <br />Ave #06, DOHS Mirpur<br /> Dhaka-1212, Banladesh</p>
              </div>
            </div>
            {/* <img className='ml-[50px] text-center h-[150px]' src="https://www.warptexbd.com/image/contact/contact-qr.png" alt="" srcset="" /> */}
            {/* <div className="flex">
              <div className="h-[55px] w-[57px] leading-[57px] rounded-[100%] mr-2 flex items-center justify-center bg-[#f7f7f7]">
                <i className='fa-regular text- fa-envelope text-[#deba27] text-[37px]'></i>
              </div>
              <div>
                <h3 class="text-[22px] text-white leading-[30px] capitalize font-medium">Email</h3>
                <p className="text-white">menon@nalamsourcingbd.com</p>
              </div>
            </div> */}
            <div className="flex">
              <div className="h-[55px] w-[57px] leading-[57px] rounded-[100%] mr-2 flex items-center justify-center bg-[#f7f7f7]">
                <i className='fa-regular fa-envelope text-[#deba27] text-[27px]'></i>
              </div>
              <div>
                <h3 class="text-[22px] text-white leading-[30px] capitalize font-medium">Managing Director</h3>
                <p className="text-white"><b>Emdadul Haque Menon</b></p>
                <p className="text-white">Whats app: +8801711272853</p>
                <p className="text-white"><b>Email:</b> menon@nalamsourcingbd.com</p>
              </div>
            </div>
            <div className="flex">
              <div className="h-[55px] w-[57px] leading-[57px] rounded-[100%] mr-2 flex items-center justify-center bg-[#f7f7f7]">
                <i className='fa-regular text- fa-envelope text-[#deba27] text-[37px]'></i>
              </div>
              <div>
                <h3 class="text-[22px] text-white leading-[30px] capitalize font-medium">Chief Executive Officer</h3>
                <p className="text-white"><b>Mahmud Hasan</b></p>
                <p className="text-white">Whats app: +8801886120240</p>
                <p className="text-white"><b>Email:</b> mahmud@nalamsourcingbd.com</p>
              </div>
            </div>
            <div className="flex">
              <div className="h-[55px] w-[57px] leading-[57px] rounded-[100%] mr-2 flex items-center justify-center bg-[#f7f7f7]">
                <i className='fa-regular text- fa-envelope text-[#deba27] text-[37px]'></i>
              </div>
              <div>
                <h3 class="text-[22px] text-white leading-[30px] capitalize font-medium">Director</h3>
                <p className="text-white"><b>Siddiqur Rahman Khokon</b></p>
                <p className="text-white">Whats app: +8801913563130</p>
                <p className="text-white"><b>Email:</b> khokon@nalamsourcingbd.com</p>
              </div>
            </div>
            {/* <div className="mx-[15px] flex items-center">
              <div className="h-[67px] w-[67px] leading-[67px] rounded-[50%] mr-2 flex items-center justify-center bg-[#f7f7f7]">
                <i className='fa-regular fa-envelope text-[#deba27] text-[37px]'></i>
              </div>
              <div>
                <h3 class="text-[22px] leading-[30px] capitalize font-medium">Address</h3>
                <p>House: 487, (3A) Road: 08, (East) Baridhara DOHS, Dhaka-1212, Banladesh</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMapAddress;