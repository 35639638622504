import React from 'react';
import './HomeWearAndOthersProducts.css';

const HomeWearAndOthersProducts = () => {
  return (
    <div>

      <div className="bg-[url(https://www.warptexbd.com/image/title-bg/product.jpg)] bg-no-repeat bg-cover block">
        <div className="bg-[#0000009a] bg-opacity-5">
          <div className="text-center py-[75px]">
            <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>
              Home Wear And Others
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-[#2da7c9]">
        <div className="container pt-24 pb-[190px] ">
          <div className="flex justify-center items-center gap-4">

            <h2 className='text-[33px] text-white font-bold'>Not Have Any Product in this Category !!!</h2>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeWearAndOthersProducts;