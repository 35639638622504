import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';
import ContactMapAddress from '../../components/ContactMapAddress/ContactMapAddress';
import useTitle from '../../hooks/useTitle';

const ContactUs = () => {
  useTitle('Contact us')
  return (
    <div>
      <ContactForm />
      <ContactMapAddress />
    </div>
  );
};

export default ContactUs;