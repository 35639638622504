import React, { useState } from 'react';
import { FaRandom, FaSearchPlus } from "react-icons/fa";
import { Link } from 'react-router-dom';
import './Production.css';


const data = [
  {
    id: 1,
    image: "https://i.ibb.co/44cg3dt/Trims-and-accessories.jpg",
    title: "Research and development",
    subTitle: "N Alam Sourcing Bd",
    url: "/service/research"
  },
  {
    id: 2,
    image: "https://i.ibb.co/8zhBsDC/Whats-App-Image-2023-02-23-at-11-38-29-PM.jpg",
    title: "Merchandising",
    subTitle: "In N Alam Sourcing Bd, our main concern is",
    url: "/service/merchandising"
  },
  {
    id: 3,
    image: "https://i.ibb.co/1mX6yhd/Swatch-On.png",
    title: "Fabric Sourcing",
    subTitle: "To ensure the best quality fabr",
    url: "/service/fabric"
  },
  {
    id: 4,
    image: "https://i.ibb.co/jHWWKqy/R-1.jpg",
    title: "Production",
    subTitle: "N Alam Sourcing Bd has more than 14 factor",
    url: "/service/production"
  },
  {
    id: 5,
    image: "https://i.ibb.co/C6RCTnp/garment-accessory-feat-lg.jpg",
    title: "QA AND QC",
    subTitle: "N Alam Sourcing Bd has a team of experienc",
    url: "/service/qaqc"
  },
  {
    id: 6,
    image: "https://i.ibb.co/z487Ksc/Logistics-sc.webp",
    title: "DELIVERY AND SHIPMENT",
    subTitle: "Proper Packing & on time Goods ",
    url: "/service/delivery"
  },
  {
    id: 7,
    image: "https://i.ibb.co/g9QnhDk/Whats-App-Image-2023-03-11-at-11-08-33-AM.jpg",
    title: "Knitting",
    subTitle: "Our Knitting factories are equi ",
    url: "/service/kitting"
  },
  {
    id: 8,
    image: "https://i.ibb.co/JHJSMZQ/ee4fbd7e0b18f450a1d9e2d19208c37f.jpg",
    title: "Embroidery",
    subTitle: "We provide our clients with all",
    url: "/service/embroidery"
  },
  {
    id: 9,
    image: "https://i.ibb.co/LrGgfkC/Whats-App-Image-2023-02-20-at-8-56-35-PM-1.jpg",
    title: "Dyeing and wash",
    subTitle: "To ensure the best quality & ex",
    url: "/service/garment"
  },
  {
    id: 10,
    image: "https://i.ibb.co/GMZ84Kh/screenprinting.jpg",
    title: "Printing",
    subTitle: "Our printing facilities are cap",
    url: "/service/printing"
  },
  {
    id: 11,
    image: "https://i.ibb.co/44cg3dt/Trims-and-accessories.jpg",
    title: "Trimming and Accessories",
    subTitle: "All our accessories and trims a",
    url: "/service/trimming"
  },
  {
    id: 12,
    image: "https://i.ibb.co/TwLm0Dn/Sample-section.jpg",
    title: "Sample Development",
    subTitle: "N Alam Sourcing Bd always give extra prior",
    url: "/service/sample"
  },
  {
    id: 2,
    image: "https://i.ibb.co/8zhBsDC/Whats-App-Image-2023-02-23-at-11-38-29-PM.jpg",
    title: "Merchandising",
    subTitle: "In N Alam Sourcing Bd, our main concern is",
    url: "/service/merchandising"
  },
  {
    id: 3,
    image: "https://i.ibb.co/1mX6yhd/Swatch-On.png",
    title: "Fabric Sourcing",
    subTitle: "To ensure the best quality fabr",
    url: "/service/fabric"
  },
  {
    id: 4,
    image: "https://i.ibb.co/jHWWKqy/R-1.jpg",
    title: "Production",
    subTitle: "N Alam Sourcing Bd has more than 14 factor",
    url: "/service/production"
  }

]

const Production = () => {

  const [products, setProducts] = useState({});

  return (
    <div>
      <div className="container text-center mt-[100px]">
        <h3 className='text-[26px] leading-[29px] tracking-[1.2] text-[#deba27]'>DEVELOPMENT, PRODUCTION & DELIVERY</h3>
        <h2 className='text-[60px] text-white leading-[65px] font-normal mb-[15px]'>N Alam Sourcing Bd. Ensure The Best Production From Every Angle</h2>
        <p className='text-white'>N Alam Sourcing Bd. is the largest peer-to-peer comparison initiative in the textile industry. It tracks the apparel material and home textile sector’s progress.</p>
      </div>
      <div data-aos="fade-up" data-aos-duration="2000" className='overflow-hidden mt-[50px] z-[-1] '>
        {/* <Slider {...settings}> */}
        <div className="production-slider">
          <div className="production-slide-track">

            {/* Slider Start  */}

            {
              data.map((product) => <>
                <div className="production-slide bg-[#f7f7f7]">
                  <div className="">
                    <div className="relative">
                      <div className='h-[232px] w-[301px] max-w-full object-cover'>
                        <img className='h-full w-full object-cover' src={product?.image} alt="" srcset="" />
                      </div>
                      <div className="overlay-items absolute top-[50%] left-[40%] w-[10%] flex items-center">
                        <label htmlFor={product?.id} onClick={() => setProducts(product)} className='mr-[6px] cursor-pointer bg-[#deba27] p-4 flex justify-center items-center'>
                          <FaSearchPlus className='text-white ' />
                        </label>
                        <Link className=' bg-[#deba27] p-4 flex justify-center items-center' to={product?.url}>
                          <FaRandom className='text-white' />
                        </Link>
                      </div>
                    </div>
                    <div className="pt-[35px] pb-[20px] px-[30px]  ">
                      <Link className='text-[14px] leading-[22px] hover:text-[#deba27]' to={product?.url}>{product?.subTitle}</Link>
                      <h3><Link className='text-[26px] font-medium leading-[36px] hover:text-[#deba27] text-black' to={product?.url}>{product?.title}</Link></h3>
                    </div>
                  </div>
                </div>

                { }
                {/* <label htmlFor="my-modal-3" className="btn">open modal</label> */}

                {/* Put this part before </body> tag */}

              </>
              )
            }


            {/* Slider End  */}
          </div>
        </div>
        {/* </Slider> */}
      </div>
      <input type="checkbox" id={products?.id} className="modal-toggle" />
      <div className="modal z-[99999]">
        <div className="modal-box relative bg-white">
          <label htmlFor={products?.id} className="btn btn-sm btn-circle text-white absolute right-2 bottom-2">✕</label>
          <div>

            <img src={products?.image} alt={products?.title} />
            <div className="pt-[35px] pb-[20px] px-[30px]  ">
              <Link className='text-[14px] leading-[22px] hover:text-[#deba27]' to={products?.url}>{products?.subTitle}</Link>
              <h3><Link className='text-[26px] font-medium leading-[36px] hover:text-[#deba27] text-black' to={products?.url}>{products?.title}</Link></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;