import React from 'react';
import Massanger from '../../common/Massanger';
import AboutUs from '../../components/AboutUs/AboutUs';
import ComplianceAndEthics from '../../components/ComplianceAndEthics/ComplianceAndEthics';
import Hero from '../../components/Hero/Hero';
import Production from '../../components/Production/Production ';
import Testimonials from '../../components/Testimonials/Testimonials';
import useTitle from '../../hooks/useTitle';

const Home = () => {
  useTitle('Home')
  return (
    <>
      <Hero />
      <AboutUs />
      <Production />
      <ComplianceAndEthics />
      <Testimonials />
      <Massanger />
    </>
  );
};

export default Home;