import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../common/Footer/Footer';
import FooterSliders from '../common/Footer/FooterSliders';
import Header from '../common/Header/Header';

const Min = () => {
  return (
    <>
      <Header />
      <Outlet />
      <FooterSliders/>
      <Footer/>
    </>
  );
};

export default Min;