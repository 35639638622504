import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';

import React from 'react';
import ScrollUpBtn from './common/ScrollUpBtn';
import ServiceLayout from './components/ServiceLayout';
import Min from './layout/Min';
import About from './pages/About/About';
import ComplianceAndEthicsDetails from './pages/ComplianceAndEthicsDetails/ComplianceAndEthicsDetails';
import ContactUs from './pages/ContactUs/ContactUs';
import Home from './pages/Home/Home';
import HomeWearAndOthersProducts from './pages/Products/HomeWearAndOthersProducts/HomeWearAndOthersProducts';
import KnitProducts from './pages/Products/KnitProducts/KnitProducts';
import Products from './pages/Products/Products';
import SweaterProducts from './pages/Products/SweaterProducts/SweaterProducts';
import WomenProducts from './pages/Products/WovenProducts/WovenProducts';
import Services from './pages/Services/Services';
import Delivery from './pages/Services/ServicesPage/Delivery';
import Embroidery from './pages/Services/ServicesPage/Embroidery';
import Fabric from './pages/Services/ServicesPage/Fabric';
import Garment from './pages/Services/ServicesPage/Garment';
import Knitting from './pages/Services/ServicesPage/Knitting';
import Merchandising from './pages/Services/ServicesPage/Merchandising';
import Printing from './pages/Services/ServicesPage/Printing';
import Production from './pages/Services/ServicesPage/Production';
import QaQc from './pages/Services/ServicesPage/QaQc';
import Research from './pages/Services/ServicesPage/Research';
import Sample from './pages/Services/ServicesPage/Sample';
import Trimming from './pages/Services/ServicesPage/Trimming';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Min />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/about',
          element: <About />
        },
        {
          path: '/ComplianceAndEthics',
          element: <ComplianceAndEthicsDetails />
        },
        {
          path: '/products/woven',
          element: <WomenProducts />
        },
        {
          path: '/products/knit',
          element: <KnitProducts />
        },
        {
          path: '/products/sweater',
          element: <SweaterProducts />
        },
        {
          path: '/products/HomeWearAndOthers',
          element: <HomeWearAndOthersProducts />
        },
        {
          path: '/contact',
          element: <ContactUs />
        },
        {
          path: '/service', element: <ServiceLayout />, children: [
            { path: '/service/research', element: <Research /> },
            { path: '/service/sample', element: <Sample /> },
            { path: '/service/merchandising', element: <Merchandising /> },
            { path: '/service/fabric', element: <Fabric /> },
            { path: '/service/production', element: <Production /> },
            { path: '/service/qaqc', element: <QaQc /> },
            { path: "/service/delivery", element: <Delivery /> },
            { path: '/service/kitting', element: <Knitting /> },
            { path: '/service/embroidery', element: <Embroidery /> },
            { path: '/service/garment', element: <Garment /> },
            { path: '/service/printing', element: <Printing /> },
            { path: '/service/trimming', element: <Trimming /> }
            ]
        },
        {
          path: '/services',
          element: <Services />
        },
        {
          path: '/products',
          element: <Products />
        },




      ]
    }
  ])

  // Router.run(router, Router.HistoryLocation, function (Handler) {
  //   React.render(<Handler />, document.body);
  // });
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
      <ScrollUpBtn />
    </>
  );
}

export default App;
