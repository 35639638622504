import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import { toast } from 'react-hot-toast';

const ContactForm = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fdfvbvw', 'template_0tjtkbe', form.current, 'exToToltL1qEWKsYV')
      .then((result) => {
        console.log(result.text);
        toast.success("from Submit Successfully!")
      }, (error) => {
        console.log(error.text);
        toast.error("Not Send From Please Try Agin!")
      });
  };

  return (
    <div style={{ boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 7%)' }}
      className='container mt-[100px] bg-[#2da7c9] rounded p-[50px]   '>
      <div className='shadow-[0_0px_20px_2px_white] rounded-lg p-6'>

        <div className="text-center pb-[11px] mb-[15px]">
          <h2 className='text-[60px]  leading-[65px] font-normal text-white'>Contact <b>Form</b></h2>
        </div>
        <p className='pb-[15px] text-center text-white text-[16px] font-normal'>Feel free to contact us through 
          <a className=' text-[#deba27] mx-2' target='blank' href="https://www.linkedin.com/company/n-alam-sourcing-bd-ltd/">LinkedIn</a>
          if you prefer.</p>

          {/* border-[#77777733] */}
        <div className="">
          <form ref={form} onSubmit={sendEmail} className='text-center' action="">
            <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1">
              <div className="mt-[5px] mb-[25px] px-[12px]">
                <input type="text" name='name' required placeholder="First Name" className="input input-bordered input- w-full pt-[13px] pb-[14px] px-[15px] align-middle	text-[14px] border-[#77777733] bg-[#f7f7f7] leading-[24px]" />
              </div>
              <div className="mt-[5px] mb-[25px] px-[12px]">
                <input type="text" name='last_name' required placeholder="Last Name" className="input input-bordered w-full pt-[13px] pb-[14px] px-[15px] align-middle	 text-[14px] border-[#77777733] bg-[#f7f7f7] leading-[24px]" />
              </div>
              <div className="mt-[5px] mb-[25px] px-[12px]">
                <input type="email" name='email' required placeholder="Your Email" className="input input-bordered w-full pt-[13px] pb-[14px] px-[15px] align-middle mx-auto text-[14px] border-[#07070733] bg-[#f7f7f7] leading-[24px]" />
              </div>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1">
              <div className="mt-[5px] mb-[25px] px-[12px]">
                <input type="text" name='company' required placeholder="Company Name" className="input input-bordered w-full pt-[13px] pb-[14px] px-[15px] align-middle	 text-[14px] border-[#77777733] bg-[#f7f7f7] leading-[24px]" />
              </div>
              <div className="mt-[5px] mb-[25px] px-[12px]">
                <input type="text" name='phone' required placeholder="Phone Number" className="input input-bordered w-full pt-[13px] pb-[14px] px-[15px] align-middle	 text-[14px] border-[#77777733] bg-[#f7f7f7] leading-[24px]" />
              </div>
            </div>
            <div className="mt-[5px] mb-[25px] px-[12px]">
              <input type="text" name='subject' required placeholder="Subject" className="input input-bordered w-full pt-[13px] pb-[14px] px-[15px] align-middle	 text-[14px] border-[#77777733] bg-[#f7f7f7] leading-[24px]" />
            </div>
            <div className="mt-[5px] mb-[25px] px-[12px]">
              <textarea placeholder="Message" required name='message' rows='5' className="input h-[147px] bordered w-full pt-[13px] pb-[14px] px-[15px] align-middle	 text-[14px] border-[#77777733] bg-[#f7f7f7] leading-[24px]" />
            </div>
             <button type="submit" className='btn border-none text-[18px] leading-[18px] pt-4 pb-[14px] px-[50px] mx-auto  bg-[#deba27] hover:bg-black text-white hover:text-[#deba27]'>Send Now</button>
         </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;