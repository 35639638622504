import React from "react";
// Import Swiper React components
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import './Hero.css';

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/effect-fade';
import "swiper/css/navigation";


// import required modules
import { Autoplay, Navigation } from "swiper";

const Hero = () => {
  // componentDidMount() {
  //   new Swiper('.swiper-container', {
  //     effect: 'fade',
  //     fadeEffect: {
  //       crossFade: true,
  //       duration: 1000 // Specify the duration in milliseconds
  //     }
  //   });
  // }

  return (
    <>
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay, EffectFade]}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        effect="fade"
        fadeEffect={{
          crossFade: true,
          // duration: 50000
        }}

        className="mySwiper relative swiper-container">
        <SwiperSlide

        >
          {({ isActive }) => (

            <div className="slider-bg w-full bg-no-repeat bg-bottom bg-cover h-[70vh] flex items-center justify-center  bg-[url(https://i.ibb.co/TrV9ZRC/woman-tailor-working-sewing-factory-1303-15841.jpg)]">


              <div className={`container z-10 md:text-right text-center md:w-[70%] w-[90%] relative `}>
                <div className="slide-border">
                  <div className={` ease-in-out duration-[2000ms] ${isActive ? 'mr-0 opacity-100' : 'mr-[50px] opacity-10 '}`}>
                    <h2 className='text-white font-normal leading-[30px] mb-4 inline-block text-[36px] tracking-[1.2px]'>
                      <span className="text-[#deba27]">N Alam Sourcing Bd Ltd</span>
                    </h2>
                  </div>
                  <div className={`ease-in-out duration-[2500ms] ${isActive ? 'mr-0 opacity-100' : 'mr-[50px] opacity-10 '}`}>
                    <h2 className="xl:text-[5rem] md:text-[3rem] text-[2rem] xl:leading-[90px] md:leading-[50px] leading-[29px] font-normal mb-4 text-white">
                      Woven, Denim, Knit &amp; Sweater <br /> Exporter </h2>
                  </div>
                  <div>
                    <button className="btn bg-[#deba27] text-[18px] leading-[18px] pt-4 pb-[14px] px-[50px] hover:bg-[#222429] rounded-none">DISCOVER MORE</button>
                  </div>
                  <div className="">
                  </div>
                </div>

              </div>
            </div>
          )
          }
        </SwiperSlide>
        <SwiperSlide

        >
          {({ isActive }) => (

            <div className="slider-bg w-full bg-no-repeat bg-bottom bg-cover h-[70vh] flex items-center justify-center  bg-[url(https://i.ibb.co/2KxQ9Fh/Whats-App-Image-2023-02-20-at-5-32-15-PM.jpg)]">


              <div className={`container z-10 md:text-right text-center md:w-[70%] w-[90%] relative `}>
                <div className="slide-border">
                  <div className={` ease-in-out duration-[2000ms] ${isActive ? 'mr-0 opacity-100' : 'mr-[50px] opacity-10 '}`}>
                    <h2 className='text-white font-normal leading-[30px] mb-4 inline-block text-[36px] tracking-[1.2px]'>
                      <span className="text-[#deba27]">N Alam Sourcing Bd Ltd</span>
                    
                    </h2>
                  </div>
                  <div className={`ease-in-out duration-[2500ms] ${isActive ? 'mr-0 opacity-100' : 'mr-[50px] opacity-10 '}`}>
                    <h2 className="xl:text-[5rem] md:text-[3rem] text-[2rem] xl:leading-[90px] md:leading-[50px] leading-[29px] font-normal mb-4 text-white">  One-Stop Solution for All Apparel Product Categories </h2>
                  </div>
                  <div>
                    <button className="btn bg-[#deba27] text-[18px] leading-[18px] pt-4 pb-[14px] px-[50px] hover:bg-[#222429] rounded-none">DISCOVER MORE</button>
                  </div>
                  <div className="">
                  </div>
                </div>

              </div>
            </div>
          )
          }
        </SwiperSlide>
        <SwiperSlide

        >
          {({ isActive }) => (

            <div className="slider-bg w-full bg-no-repeat bg-center bg-cover h-[70vh] flex items-center justify-center  bg-[url(https://i.ibb.co/qCj5g3D/a1bd99437d65f043512b791937bdd69b.jpg)]">


              <div className={`container z-10 md:text-right text-center md:w-[70%] w-[90%] relative `}>
                <div className="slide-border">
                  <div className={` ease-in-out duration-[2000ms] ${isActive ? 'mr-0 opacity-100' : 'mr-[50px] opacity-10 '}`}>
                    <h2 className='text-white font-normal leading-[30px] mb-4 inline-block text-[36px] tracking-[1.2px]'>
                      <span className="text-[#deba27]">N Alam Sourcing Bd Ltd</span>
                
                    </h2>
                  </div>
                  <div className={`ease-in-out duration-[2500ms] ${isActive ? 'mr-0 opacity-100' : 'mr-[50px] opacity-10 '}`}>
                    <h2 className="xl:text-[5rem] md:text-[3rem] text-[2rem] xl:leading-[90px] md:leading-[50px] leading-[29px] font-normal mb-4 text-white">A Leading Apparel Buying-hub, Sourcing & Exporter </h2>
                  </div>
                  <div>
                    <button className="btn bg-[#deba27] text-[18px] leading-[18px] pt-4 pb-[14px] px-[50px] hover:bg-[#222429] rounded-none">DISCOVER MORE</button>
                  </div>
                  <div className="">
                  </div>
                </div>

              </div>
            </div>
          )
          }
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Hero;