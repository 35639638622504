import React from "react";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

const ScrollUpBtn = () => {
  return (
    <div>
      {/* background: 'rgb(225 191 61) */}
      <ScrollUpButton
        EasingType="easeInQuad"
        style={{ 
          width: '40px', 
          height: '40px',
          background: 'skyblue',
           fill: '#FFF', bottom: '120px',
            boxShadow: '0px 0 8px 1px black', 
            padding: '15px', fontSize: '15px',
              borderRadius: '30%'
             }}
      />
    
    </div>
  );
}
export default ScrollUpBtn;