import React from 'react';
import { Link } from 'react-router-dom';
// import CountUp from 'react-countup';


const ComplianceAndEthics = () => {
  return (
    <div className='xl:mb-[100px] md:mb-[250px] mb-24'>
      <div className="py-[100px] bg-[url(https://www.warptexbd.com/image/compliance-ethics/compliance-background.jpg)] bg-no-repeat bg-cover bg-center bg-fixed relative">
        <div className="container">
          <h2 className='text-[50px] text-white leading-[64px] font-medium mb-[15px]'>Compliance And Ethics</h2>
        </div>
      </div>

      <div className="">
        <div className='container'>
          <div className="grid md:grid-cols-2 grid-cols-1">
            <div className="relative">
              <div className="bg-white z-[9999] md:border-none border-t-[10px] border-t-[#dabe27]">
                <div className=" border-t-[10px] border-t-[#dabe27] md:bg-[url(https://www.warptexbd.com/image/compliance-ethics/compliance.jpg)] bg-center bg-cover bg-none md:min-h-[500px] md:-ml-[500px]"></div>

              </div>
            </div>
            <div className="relative shadow">
              <div className="bg-white md:-top-[150px] top-0 left-0 md:absolute relative z-[9999]">
                <div className=" pt-[60px] pb-[65px] px-[45px] ">
                  <h3 className='text-[26px] leading-[29px] tracking-[1.2] uppercase font-normal text-[#deba27]'>N Alam Sourcing Bd Ltd</h3>
                  <h2 className='text-[60px] leading-[65px] font-normal text-[#222429] mb-[15px]'>Obey All Legal & Social Compliance Guided By Laws</h2>
                  <p className='text-[16px] leading-[26px] font-normal'>
                    We do obey all legal & social compliance guided by laws and regulatory bodies as per local government order & global requirements. Some of our major compliance issues are:
                  </p>

                  <div className="mt-[30px]">
                    <div className="grid md:grid-cols-2 grid-cols-1">
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center border-r-[1px] mr-4 p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Pure Drinking Water</span>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Electric Shock Prevention</span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="grid md:grid-cols-2 grid-cols-1">
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center border-r-[1px] mr-4 p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Salary On-Time</span>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Maternity Leave</span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="grid md:grid-cols-2 grid-cols-1">
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center border-r-[1px] mr-4 p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Health And Safety</span>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Lighting And Ventilation</span>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="grid md:grid-cols-2 grid-cols-1">
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center border-r-[1px] mr-4 p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>No Child Labor</span>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center p-4">
                        <div className="flex items-center justify-center bg-[#deba27] rounded-full p-2 h-[25px] w-[25px] leading-[25px]">
                          <i class="fa-solid fa-check text-[16px] text-white"></i>
                        </div>
                        <div className='pl-4'>
                          <span className='text-[22px] leading-[30px] font-medium capitalize'>Working Hour</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container relative">
        <div data-aos="fade-up" data-aos-duration="2000" className="flex items-center bg-[#dabe27] p-[30px] xl:left-[280px] xl:-top-[70px] md:top-[80px] z-[9999] w-full max-w-[575px] md:absolute">
          <div className="w-8/12 mx-4 border-r-[1px] border-r-white">
            <p className='text-[26px] leading-[36px] text-white font-medium'>Read More About Compliance & Ethics</p>

          </div>
          <div className="w-4/12 mx-4">
            <div className="">

              <Link to="/ComplianceAndEthics" className="bg-white relative rounded-[50%] flex items-center justify-center h-[50px] w-[50px] leading-[50px] z-[2]">
                <span class="animate-ping  absolute duration-300 delay-500 inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                <i className="fa-solid fa-link text-[26px] text-[#dabe27]"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceAndEthics;