import React from 'react';
import { FaSearchPlus } from 'react-icons/fa';
import './SweaterProducts.css';

const SweaterProducts = () => {
  const sweaterImg = [
    {
      id: 1,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (12).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 2,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (13).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 3,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (14).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 4,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (15).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 5,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (16).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 7,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (17).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 8,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (18).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 9,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (19).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 10,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (2).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 11,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (20).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 12,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (21).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 13,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (22).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 14,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (23).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 15,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (24).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 16,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (25).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 17,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (26).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 18,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (27).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 19,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (28).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 20,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (29).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 21,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (3).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 22,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (30).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 20,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (31).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 23,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (32).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 24,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (35).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 25,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (36).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 26,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (37).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 20,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (38).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 27,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (39).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 28,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (4).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 29,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (40).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 30,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (41).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 31,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (42).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 32,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (43).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 33,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (44).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 34,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (45).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 35,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (46).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 36,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (47).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 37,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (48).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 38,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (49).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 39,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (5).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 40,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (50).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 41,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (51).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 42,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (52).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 43,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (53).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 44,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (54).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 45,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (55).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 46,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (56).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 47,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (57).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 48,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (58).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 49,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (59).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 60,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (6).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 61,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (60).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 62,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (61).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 63,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (62).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 64,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (63).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 65,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (64).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 66,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (65).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 67,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (66).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 68,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (67).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 69,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (68).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 70,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (69).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 71,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (7).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 72,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (70).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },
    {
      id: 73,
      img: "https://www.warptexbd.com/public/img-sweater/Man_Sweater (71).jpg",
      alt: "Sweater | N Alam Sourcing Bd Limited"
    },

  ]




  return (
    <div>
      <div className="bg-[url(https://www.warptexbd.com/image/title-bg/product.jpg)] bg-no-repeat bg-cover block">
        <div className="bg-[#0000009a] bg-opacity-5">
          <div className="text-center py-[75px]">
            <h2 className='text-[50px] leading-[64px] font-medium capitalize mb-4 text-[#FFFFFF] text-center'>
              Sweater
            </h2>
          </div>
        </div>
      </div>

      <div className="bg-[#2da7c9]">
        <div className="container pt-24 pb-[190px] ">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">

            {

              sweaterImg.map((img) => <>
                <div className=" product-card bg-white">
                  <div className="">
                    <img className='w-full h-full' src={img?.img} alt={img?.alt} />
                    <label htmlFor={img?.id} className="zoom-icon flex items-center justify-center bg-white hover:bg-[#deba27]">
                      <FaSearchPlus className='' />
                    </label>
                  </div>
                </div>
                {/* The button to open modal */}
                {/* <label htmlFor="my-modal-3" className="btn">open modal</label> */}

                {/* Put this part before </body> tag */}
                <input type="checkbox" id={img?.id} className="modal-toggle" />
                <div className="modal">
                  <div className="modal-box relative  bg-white">
                    <label htmlFor={img?.id} className="btn btn-sm btn-circle bg-[#999] text-white absolute right-2 bottom-2">✕</label>
                    <div>
                      <img src={img?.img} alt={img?.alt} />
                    </div>
                  </div>
                </div>
              </>
              )
            }

          </div>
        </div>
      </div>



    </div>
  );
};

export default SweaterProducts;