import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import './Footer.css';
import './Locations.css';


const notify = (event) => {
  event.preventDefault();
  toast.success("Sent The Mail")
};
const Footer = () => {


  return (
    <footer className='relative'>
      <div className="md:absolute top-[-0%] z-10 left-0 w-full p-15 bottom-5">
        <div style={{ boxShadow: 'rgb(43 52 59 / 10%) 0px 0px 10px 0px' }}
          className="md:flex md:flex-wrap block items-center md:mb-[-100px] mb-0 z-[999] bg-blue pt-[32px] pb-[40px] px-[45px] w-full md:w-[80%] mx-auto">
          <div className="xl:w-5/12 lg:w-6/12 md:w-full w-full">
            <h4 className='uppercase xl:text-[30px] text-[22px] leading-[29px] tracking-[3px] text-[#deba27] font-normal'>FOR BUSINESS COLLABORATION</h4>
            <h2 className='uppercase 2xl:text-[3rem] xl:text-[2.5rem] md:text-[2.2rem] text-[2rem] leading-[60px] text-black font-medium'>Discuss With Our Great Team</h2>
          </div>
          <div className="lg:w-4/12 md:w-8/12 w-full md:flex block justify-start">
            <div>
              <h4 className=' capitalize text-[22px] leading-[30px] text-black font-medium'>Send An Email</h4>
              <h2 className='normal text-[26px] leading-[32px] text-[#deba27] font-medium'>
                <a href="mailto:menon@nalamsourcingbd.com">info@nalamsourcingbd.com</a>
              </h2>
            </div>
          </div>
          <div className="lg:w-2/12 md:w-4/12 w-full">
            <Link to="/contact" className='btn bg-left transition-all duration-700 rounded-none bg-transparent hover:bg-black border-[1px] border-black text-black hover:text-white' style={{ backgroundSize: "10px" }}>GET INVOLVED NOW!</Link>
          </div>
        </div>
      </div>
      {/* style={{ backgroundImage: 'url(https://www.warptexbd.com/home-4/images/bg-image/footer-bg.png)', backgroundColor: '#012740' }} */}
      <div className=" relative" style={{backgroundColor: '#2da7c9' }}>
        <div className="pt-24">
          <div className='w-4/5 mx-auto mb-10'>
            <div className="grid gap-5 justify-center grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div className="first">
                <div className="">
                  <div className="">
                    <h4 className='text-2xl font-medium text-white my-10 '>About N Alam Sourcing Bd Ltd</h4>
                  </div>
                  <p className='text-lg text-slate-300 font-medium'>N Alam Sourcing (bd) Ltd. Buying and Sourcing agency from Bangladesh offers its international buyers. the ultimate solution for sourcing excellent quality Textiles, Apparels, Accessories, Handicraft Gift items and home textile also from Bangladesh.</p>
                  <div className="location flex items-center gap-2 my-3">
                    <i className="fa-solid fa-location-dot text-white"></i>
                    <i class="fa-regular fa-envelope-open text-white"></i>
                    <i class="fa-solid fa-phone-volume text-white"></i>
                  </div>
                </div>
              </div>
              <div className="second max-w-[280px]">
                <div className="">
                  <h4 className='text-2xl font-medium text-white my-10'>Useful Links</h4>
                </div>
                <div className="grid lg:grid-cols-2 grid-cols-1">
                  <ul>
                    <li><Link to="/" className='transition-all duration-700 nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>Home</p></Link></li>
                    <li><Link to="/about" className='nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>About Us</p></Link></li>
                    <li><Link to="/products" className='nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>Products</p></Link></li>
                    <li><Link to="/ComplianceAndEthics" className='nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>Compliance & Ethics</p></Link></li>
                  </ul>
                  <ul>
                    <li><Link to=" " className='nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>FAQ</p></Link></li>
                    <li><Link to="/services" className='nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>Services</p></Link></li>
                    <li><Link to="/contact" className='nav-items flex items-center text-[16px] leading-[26px] text-[#ffffff99] hover:text-[#deba27]'><i class="fa-solid fa-angles-right duration-1000"></i> <p>Contact Us</p></Link></li>
                  </ul>
                </div>
              </div>
              <div className="fourth ml-3">
                <div className="">
                  <h4 className='text-2xl font-medium text-white my-10'>SignUp For Updates</h4>
                </div>
                <div>
                  <p className='text-lg text-slate-300 font-medium'>SignUp to get news about N Alam Sourcing Bd Ltd.</p>
                  <form onSubmit={notify}>
                    <input type="email" required placeholder="Your Email" className="input border-slate-400 bg-[#e3e1e1] focus:outline-none focus:border-amber-400 text-white my-4	rounded-none w-2/3" />

                    <br />

                    <button className='btn bg-[#deba27] hover:bg-white text-white  hover:text-[#deba27] text-[20px]   rounded-none w-2/3'>Subscribe</button>
                  </form>
                  <Toaster />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#23a7cc] text-start border-t-[1px] border-[#ff]">
            <div className="container flex items-center justify-between	">
              <p className='text-slate-300 text-md font-medium text-[15px] '>Copyright © 2024 <span className='font-bold text-[#deba27]'>N Alam Sourcing Bd Ltd</span></p>
            <div>
               <p className='text-slate-300 text-md font-medium text-[15px] '>
                <i class="fa-solid fa-thumbtack text-[14px] leading-[46px] text-[#cca500] mr-2"></i>
                <b className='text-[18px]'>Corporate Address:</b> 768(Flat-1B), Road: 10, Ave #06, DOHS Mirpur, Dhaka-1216, Bangladesh                   
              </p>
              <p className='text-slate-300 text-md font-medium text-[15px] '>
                <i class="fa-solid fa-thumbtack text-[14px] leading-[46px] text-[#cca500] mr-2"></i>
                <b className='text-[18px]'>Register Address:</b> House # 14, Road: 09, Pallabi,  Mirpur, Dhaka-1216, Bangladesh                
              </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;