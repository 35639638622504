import React from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';


const Massanger = () => {
    return (
        <div>
            <MessengerCustomerChat
                pageId="100088022113313"
                appId="171757002419426"
            />,

        </div>
    );
};

export default Massanger;